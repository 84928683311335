import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import Link from "next/link";
import MagnifierIcon from "/components/icons/magnifier";
import PlusIcon from "/components/icons/plus";
// import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/outline";

function useSkipper() {
  const shouldSkipRef = useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to pageIndex a pagination reset temporarily
  const pageIndex = useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, pageIndex];
}

function Filter({ column, table }) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={columnFilterValue?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [e.target.value, old?.[1]])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={columnFilterValue?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old) => [old?.[0], e.target.value])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <input
      type="text"
      value={columnFilterValue ?? ""}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}

const BasicTable = ({
  columnData,
  rowData,
  dataType,
  pageIndex,
  pageSize,
  totalRows,
  setPagination,
  setSorting,
  sorting,
  isValidating,
}) => {
  const columns = useMemo(() => columnData, [columnData]);
  const memoizedData = useMemo(() => {
    if (rowData) {
      return rowData;
    } else {
      return [];
    }
  }, [rowData]);
  const [data, setData] = useState(memoizedData);
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const [rowSelection, setRowSelection] = React.useState({});

  useEffect(() => {
    setData(memoizedData);
  }, [memoizedData]);

  const [autoResetPageIndex, pageIndexAutoResetPageIndex] = useSkipper();

  const {
    getHeaderGroups,
    getRowModel,
    getState,
    getPageCount,
    getCanNextPage,
    nextPage,
    previousPage,
    getCanPreviousPage,
  } = useReactTable({
    data,
    columns,
    // defaultColumn,
    getCoreRowModel: getCoreRowModel(),
    // getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    manualPagination: true,
    manualSorting: true,
    autoResetSortBy: false,
    autoResetExpanded: false,
    autoResetPage: false,
    state: {
      pagination,
      sorting,
    },
    enableSorting: true,
    pageCount: Math.ceil(totalRows / pageSize),
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // setSorting,
    autoResetPageIndex: false,

    // Provide our updateData function to our table meta
    meta: {
      updateMyData: (rowIndex, columnId, value) => {
        console.log("UPDATE MY DATA", rowIndex, columnId, value);
        // Skip page index reset until after next rerender
        pageIndexAutoResetPageIndex();
        setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
    debugTable: true,
  });

  if (!rowData)
    return (
      <div className="relative h-screen flex justify-center items-center flex-col">
        {/* <LoadingScreen /> */}
        <h1 className="mt-4 text-center text-xl font-bold">loading data</h1>
      </div>
    );
  return (
    <div>
      <div className="rounded-lg border border-gray-200 bg-white overflow-x-scroll overflow-y-hidden ">
        <div className="w-full max-w-full">
          {getRowModel().rows.length ? (
            <table className="table-auto w-full">
              <thead className="bg-gray-100 rounded-xl">
                {getHeaderGroups().map((headerGroup, index) => (
                  <tr key={index} className="bg-gray-100">
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={index}
                        className="py-3 pl-6 text-left text-xs text-default font-medium whitespace-nowrap"
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? "select-none cursor-pointer flex items-center gap-1"
                                : "",
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "down",
                              desc: "up",
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        )}

                        {/* {column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                className={`${
                  isValidating ? "relative blur-sm" : ""
                } transition-all duration-1000 pb-4`}
              >
                {getRowModel().rows.map((row, index) => {
                  return (
                    <tr
                      key={index}
                      className="w-full p-2 mb-4 border-y border-gray-200 rounded"
                    >
                      {row.getVisibleCells().map((cell, index) => {
                        const headerName = columns[index]?.header;
                        return (
                          <td
                            key={index}
                            before={headerName ? headerName() : null}
                            className={`relative mb-2 py-2 pl-6 last:py-0 last:pr-6 border-t border-gray-200 first:border-none md:py-3 md:before:hidden whitespace-nowrap`}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="w-full text-center font-medium text-lg py-16 px-8 max-w-xl mx-auto">
              {dataType === "songkick-shows" ? (
                <div>
                  {" "}
                  <h1 className="text-2xl md:text-4xl font-semibold mb-4">
                    All Known Shows Imported
                  </h1>
                  <p className="mb-8 mx-auto max-w-md">
                    Need to add some more?
                  </p>
                  <div className="flex gap-4 flex-wrap">
                    <Link
                      href="/shows/create"
                      className="flex items-center justify-center font-semibold px-3 py-4 text-center bg-gray-50 border-2 hover:border-blue-600 border-gray-200 w-full rounded-lg shrink-0"
                    >
                      Create Show
                    </Link>
                    <a
                      href="mailto:jm@blindcreative.com"
                      className="font-semibold px-3 py-4 text-center bg-gray-50 border-2 hover:border-blue-600 border-gray-200 w-full rounded-lg leading-tight shrink-0"
                    >
                      Wish you could import via csv? <br />
                      Click here to let us know
                    </a>
                    <Link
                      href="/shows"
                      className="flex items-center justify-center font-semibold px-3 py-4 text-center bg-gray-50 border-2 hover:border-blue-600 border-gray-200 w-full rounded-lg shrink-0"
                    >
                      View All Shows
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center items-center">
                  <div className="inline-block relative overflow-hidden bg-accent100 text-accent p-2 rounded-full mr-3">
                    <MagnifierIcon />
                  </div>
                  <h2 className="mb-4">No links found</h2>
                  <Link
                    href="/create-link"
                    className="flex shrink-0 items-center bg-accent text-white px-4 py-2 font-semibold text-sm rounded-lg"
                  >
                    <span className="mr-2">
                      <PlusIcon />
                    </span>
                    <span>New Link</span>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
        {/* Pagination */}
        {/* <div className="mt-3 mb-4 mx-6 flex justify-between items-center gap-2">
          <div>
            {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!getCanPreviousPage()}
          >
            {"<<"}
          </button> */}
        {/* <button
              className="rounded-lg border px-3 py-2 text-sm font-semibold text-gray-700"
              onClick={() => previousPage()}
              disabled={!getCanPreviousPage()}
            >
              {"Previous"}
            </button>
          </div>
          {getPageCount() ? (
            <span className="flex items-center gap-1 text-sm font-medium text-gray-700">
              <div>Page</div>
              {getState().pagination.pageIndex + 1} of {getPageCount()}
            </span>
          ) : null}

          <div>
            <button
              className="rounded-lg border px-3 py-2 text-sm font-semibold text-gray-700"
              onClick={() => nextPage()}
              disabled={!getCanNextPage()}
            >
              {"Next"}
            </button> */}
        {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button> */}
        {/* <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {">>"}
          </button> */}
      </div>
      {/* <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16"
          />
        </span> */}
      {/* <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select> 
        </div> 
      </div>*/}
    </div>
  );
};

export default BasicTable;

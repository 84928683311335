import Link from "next/link";
export default function PageHeading({
  heading,
  description,
  button = false,
  classNames = "mb-8 pb-5",
}) {
  return (
    <div
      className={`${classNames} flex items-start justify-between border-b border-gray-200 `}
    >
      <div className="grow">
        <h1 className="text-primary font-medium text-3xl leading-10">
          {heading}
        </h1>
        {description ? (
          <div className="mt-1 text-default">{description}</div>
        ) : null}
      </div>
      {button && button.link ? (
        <Link
          href={button?.link}
          className="flex shrink-0 items-center bg-accent text-white px-4 py-2 font-semibold text-sm rounded-lg"
        >
          {button?.icon ? <span className="mr-2">{button.icon}</span> : null}
          <span>{button?.text}</span>
        </Link>
      ) : null}
      {button && button.action ? (
        <button
          onClick={button?.action}
          className="flex shrink-0 items-center bg-accent text-white px-4 py-2 font-semibold text-sm rounded-lg"
        >
          {button?.icon ? <span className="mr-2">{button.icon}</span> : null}
          <span>{button?.text}</span>
        </button>
      ) : null}
    </div>
  );
}
